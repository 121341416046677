:root {
  --darkest: #321f2e;
  --darker: #351615;
  --moredark: #411F22;
  --dark: #6b3231;
  --medium: #4c4040;
  --lightmedium: #C6A35F;
  --light: #dfc69c;
  --lighter: #FFFFE8;
  --lightest: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background-image: linear-gradient(var(--darkest), var(--dark), var(--dark), var(--darkest));
}