@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
.c1{
    color: white;
    margin: 0% 10%;
}
h1{
    margin:2%;
    font-family: "Playfair Display", serif;
}
p{  
    font-family: 'Poppins', 'sans-serif';
}
.introText{
    font-size: 1.5rem;
}
.details1{
    border-radius: 4%;
    width: 100%;
    height: 100%;
}
.detailsImgContainer{
    height: 39rem;
}
.firstSubSection{
    background-color:var(--lightmedium);
    position: absolute;
    left :0;
    margin:2rem 0 2rem 0;
    padding: 1rem 7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}
.firstSubSection h1{
    color:var(--moredark);
    margin: 0 0 2rem 0 !important
}
.firstSubSection p{
    margin-right:20rem
}
.allSubSection{
    margin-top: 18rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.allSubSection_Card{
    background-color:var(--lightmedium);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    padding: 4rem 0.5rem;
}
.allSubSection_Card h1{
    color:var(--moredark);
    text-align: center;
}
.allSubSection_Card p{
    text-align: center;
    margin: 2rem 0 4rem 0;
}
.circle1 img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    align-self: center;
}
.circle2{
    background-color: var(--lightest);
    border-radius: 3.5rem;
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle2 img {
    width: 4rem;
    height: 4rem;
    object-fit:contain;
    align-self: center;
    justify-self: center;
   
}
.circle3{
    border-radius: 50%;
    border-color: var(--lightmedium);
    border-width: 1px;
    border-style: solid;
    width: 20rem;
    height: 20rem;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:2rem 4rem 0rem 0rem
}
.circle3 img {
    width: 14rem;
    height: 14rem;
    object-fit:contain;
    align-self: center;
    justify-self: center;
   
}
.thirdSubSection{
    margin:2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
.queryHeader{
    color: var(--lightest);
    font-family: "Playfair Display", serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 4rem;
    text-align: center;
}
.queryContainer{
    display: flex;
    flex-direction: row;
}
.queryCard{
    background-color: var(--lightmedium);
    margin: 1rem;
    padding: 2rem;
    border-radius: 2rem;

}
.queryCard h5{
    font-size: 1rem;
    margin-bottom: 1rem;
}
/* .thirdSubSection h1{
    color:var(--moredark);
    margin: 0 0 2rem 0 !important
} */
@media screen and (max-width: 650px) {
    .detailsImgContainer{
        height: 15rem;
    } 
    .firstSubSection{
        position:static;
        padding: 1rem;
        border-radius: 2rem;
        flex-direction: column-reverse;
    }
    .firstSubSection p{
        margin-right:0;
    }
    .introText{
        font-size: 0.75rem;
    }
    .allSubSection{
        margin-top:1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .allSubSection_Card{
        margin: 0 0 2rem 0;
        padding: 1rem 0.5rem;
    }
    .allSubSection_Card p{
        text-align: center;
        margin: 1rem 0 1rem 0;
    }
    .thirdSubSection{
        margin:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }
    .circle3{
        width: 10rem;
        height: 10rem;
        padding: 4rem;
        margin:1rem 0;
    }
    .circle3 img{
        width: 7rem;
        height: 7rem;
    }
    .queryContainer{
        display: flex;
        flex-direction: column;
    }
}