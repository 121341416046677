@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


.ContainerDiv {
    padding: 30px;
}

.NameH1 {
    font-weight: 300;
    font-size: 3rem;
    color: var(--lightest);
    font-family: 'Poppins', 'sans-serif';
    margin-left: 5%
}

.P1Img1Div {
    display: flex;
    flex-direction: row;
    margin-top: 4%;
    justify-content: space-between;
}

.P1P {
    color: var(--lightest);
    font-family: 'Poppins', 'sans-serif';
    margin-left: 5%;
    margin-right: 5%;

}

.ExperienceH1 {
    color: var(--light);
    margin: 4%;
    font-size: 4rem;
}

.P2Img2Div {
    display: flex;
    flex-direction: row;
    margin: 4%;
    justify-content: space-around;
}

.P2P {
    color: var(--light);
    font-family: 'Poppins', 'sans-serif';
    margin-top: 10%;
}

.Home2Img {
    width: 40%;
}

.P2a {
    font-size: 4rem;
    font-family: "Playfair Display", serif;
    font-weight: 500;
}

.P2b {
    font-size: 2rem;
    font-weight: 500;
}

.catMainDiv {
    display: flex;
    flex-direction: row;
    margin-top: 12%;
}

.cat {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catImg {
    width: 40%;
}

.cat h1 {
    font-size: 2.5rem;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: var(--light);

}

.cat p {
    color: var(--lighter);
    text-align: center;
}

.fullReadingsH1 {
    font-family: "Playfair Display", serif;
    color: var(--light);
    font-size: 3.8rem;
}

.offerDiv {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin: 10%
}

@media screen and (max-width: 650px) {
    .P1Img1Div {
        flex-direction: column-reverse;
    }

    .NameH1 {
        font-weight: 500;
        font-size: 2rem;
        text-align: center
    }

    .ExperienceH1 {
        margin-top: 10%;
        font-weight: 600;
        text-align: center;
        font-size: 2.5rem;
    }

    .P2Img2Div {
        flex-direction: column-reverse;
    }

    .catMainDiv {
        flex-direction: column;
    }

    .catMainDiv div {
        margin: 10%;
    }

    .Home1Img,
    .Home2Img {
        width: 90%;
        align-self: center;
        margin-bottom: 15%;
        margin-top: 10%
    }

    .P2a {
        font-size: 3rem;
    }

    .P2b {
        font-size: 2rem;
    }

    .P2P {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .P2Img2Div {
        align-items: center;
    }

    .catImg {
        width: 30%;
    }

    .cat h1 {
        font-size: 1.5rem;
    }

    .fullReadingsH1 {
        font-size: 2rem;
    }
}