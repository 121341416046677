@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.ContainerNav {
    padding: 30px 75px;
}

.LogoTitleHamDiv {
    display: flex;
    flex-direction: row;
}

.LogoImg {
    width: 150px
}

.TitleH1 {
    font-weight: 300;
    font-size: 3rem;
    margin-top: 32px;
    color: var(--light);
    font-family: 'Poppins', 'sans-serif';
}

.MenusUl,
.InactiveMenusUl {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin-left: 30px;
    margin-top: 20px;
}

.active {
    font-weight: 600;
}

.inactive {
    font-weight: 200;
}

.MenuL a:hover {
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.MenuL a {

    text-decoration: none;
    font-size: 1.5rem;
    color: var(--light);
    font-family: 'Poppins';
    margin-right: 3rem;
}

.ToggleDiv {
    display: none;
}



/* ONE ROW  */
@media screen and (max-width: 650px) {
    .ContainerNav {
        padding: 0px;
    }

    .LogoImg {
        width: 100px
    }

    .TitleH1 {
        font-size: 1.5rem;
    }

    .TitleHamDiv {
        display: flex;
        flex-direction: row;
    }

    .ToggleDiv {
        display: flex;
        position: absolute;
        left: 85%;
        top: 7.5%;
    }

    .ToggleDiv i {
        color: var(--light);

    }

    .InactiveMenusUl {
        display: none;
    }

    .MenusUl {

        flex-direction: column;
        list-style-type: none;
        align-items: flex-end;
        margin-top: -7%;
    }

    .MenuL a {
        /* background-color: var(--light); */
        letter-spacing: 1px;
        font-size: 1.5rem;
    }
}

/* @media screen and (max-width: 650px) {
    .ContainerNav {
        padding: 20px;
    }

    .LogoTitleHamDiv {
        flex-direction: column;
    }

    .LogoDiv {
        display: flex;
        justify-content: center;
    }

    .LogoImg {
        width: 100px
    }

    .TitleH1 {
        margin-top: 0.5rem;
        font-size: 1.5rem;
    }

    .TitleHamDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ToggleDiv {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .ToggleDiv i {
        color: var(--light);

    }

    .InactiveMenusUl {
        display: none;
    }

    .MenusUl {
        flex-direction: column;
        list-style-type: none;
        align-items: flex-end;
    }

    .MenuL a {
        letter-spacing: 1px;
        font-size: 1rem;
    }
} */