.Headings {
    color: var(--lightest);
    font-family: "Playfair Display", serif;
    font-size: 4rem;
    font-weight: 300;
    margin-bottom: 4rem;
}

.SideHeading {
    text-align: start;
    margin-left: 6rem;
}

.CenterHeading {
    text-align: center;
}

@media screen and (max-width: 650px) {
    .Headings {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .SideHeading {
        margin-left: 1rem;
    }

}