.FooterContainer {
    display: flex;
    flex-direction: row;
    margin: 3% 10%;
    justify-content: space-between;
}

.FooterContainer div {
    display: flex;
    flex-direction: column;
}

.Heading {
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 25px
}

.Links {
    text-align: end;
    margin-bottom: 25px
}

.FooterContainer div div {
    display: flex;
    flex-direction: row;
    margin: 4%;
    align-items: center;
}

.FooterContainer div div p {
    font-size: 24px;
    font-weight: 300;
}

.FooterContainer p {
    color: var(--lightest)
}

.FooterContainer i {
    color: var(--lightest);
    margin-right: 10px;
}

.FooterContainer a {
    margin-top: 3%;
}

.quicklinks {
    color: var(--lightest);
    text-decoration: none;
    font-size: 24px;
    font-weight: 300;
}

.CopyrightContainer {
    background-color: var(--light);
    padding: 12px;
}

.CopyrightContainer p {
    font-size: 18px;
    color: var(--dark);
    font-weight: 500;
}

.quicklinks:hover {
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

i:hover {
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 650px) {
    .FooterContainer {
        flex-direction: column;
    }

    .Links {
        text-align: start;
    }

    .Heading {
        font-size: 1.5rem;
        margin-top: 10%;
    }

    .quicklinks,
    .FooterContainer div div p {
        font-size: 1rem;
    }

    .CopyrightContainer p {
        font-size: 0.75rem;
        font-weight: 500;
    }
}