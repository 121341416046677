.ServiceContainer {
    display: flex;
    flex-direction: column;
}

.ServiceH1 {
    font-weight: 300;
    font-size: 3.5rem;
    color: var(--lightest);
    font-family: 'Poppins', 'sans-serif';
    margin-left: 8%
}

.ServicesUl {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3%;
}

.ServiceL:hover {
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    background-color: var(--lighter);
}

.ServiceL {
    font-weight: 800;
    text-decoration: none;
    font-size: 2rem;
    color: var(--dark);
    font-family: "Playfair Display", serif;
    margin-right: 3rem;
    background-color: var(--light);
    width: 289px;
    margin: 5%;
    height: 280px;
    border-radius: 19px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.LinkContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.circle img {
    background-color: var(--lightest);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    align-self: center;
}

@media screen and (max-width: 650px) {
    .ServiceH1 {
        font-size: 2.5rem;
        text-align: center;
    }

}