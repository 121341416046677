.FormFields {
    display: flex;
    justify-content: space-around;
}

.FormFields label {
    color: var(--light);
    font-size: 1.5rem;
}

.FormFields .col-25 {
    margin: 2rem 0rem 1rem 0rem;
}

.FormFields input,
textarea {
    padding: 1rem 2rem;
    background-color: var(--dark);
    border: 1px solid var(--light);
    width: 30rem;
    font-size: 1.5rem;
    color: var(--light)
}

::placeholder {
    color: var(--light);
    opacity: 0.5;
}

textarea {
    height: 100%;
    text-align: center;
}

.textAreaDiv {
    height: 85%;
    display: inline-table
}


/* 
input[type="date"]::before {
    color: var(--light);
    opacity: 0.5;
    content: attr(placeholder);
}

input[type="date"] {
    color: var(--dark)
}

input[type="date"]:focus,
input[type="date"]:valid {
    color: var(--light)
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
    content: "" !important;
}
*/
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(80%) sepia(10%) saturate(1000%) hue-rotate(5deg);
}

@media screen and (max-width: 650px) {
    .FormFields {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .FormFields input,
    textarea {
        padding: 1rem 2rem;
        width: 20rem;
        font-size: 1rem;
    }

    .textAreaDiv {
        height: 10%;
    }


    .Form .Button {
        font-size: 1.5rem;
    }

    .FormFields input[type="date"] {
        height: 3.5rem;
    }
}