.Button {
    color: var(--dark);
    background-color: var(--light);
    border: 1px var(--light) solid;
    border-radius: 3rem;
    font-size: 2rem;
    font-weight: bold;
    padding: 0.75rem 3rem;
}

.Button:hover {
    color: var(--light);
    background-color: var(--darker);
    transition: all 0.2s ease-in-out;
}

.ButtonDiv {
    margin: 3rem;
    text-align: center;
}

.StartButtonDiv {
    margin-top: 3rem;
}

@media screen and (max-width: 650px) {
    .Button {
        font-size: 1rem;
        padding: 1rem 2rem;
    }

    .ButtonDiv,
    .StartButtonDiv {
        margin: 2rem;
        text-align: center;
    }
}