.offerHeader {
    color: var(--lightest);
    font-family: "Playfair Display", serif;
    font-size: 3rem;
    font-weight: 800;
    margin: 0 20rem;
    text-align: center;
}
.offerText1{
    text-align: center;
    color: var(--lightest);
    margin-top: 2rem;
}
.queryContainer{
    color:var(--lightest)
}
.queryOffer{
    margin: 0 4rem;
}
/* .formListConOffers{
    display: flex;
    flex-direction: row;
}
.formListConOffers .FormFields input,
.formListConOffers .FormFields textarea{
    font-size: 1rem;
    padding: 0.5rem;
}
.formListConOffers .textAreaDiv {
    width: 1rem;
} */
.formHeader {
    color: var(--lightest);
    font-family: "Playfair Display", serif;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
}
 .getAnswersOffers{
    background-color: var(--lightmedium);
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: space-evenly;
    padding: 1.5rem;
 }
 .getAnswersOffers h2 {
    color: var(--moredark)
 }
 .getAnswersOffers ul{
    color: var(--lightest);
 }
 .offerTextImgCon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 5px solid var(--lightmedium);
    margin: 3rem 10rem;
    border-radius: 2rem;
    color: var(--lightest);
 }
 
 .offerText2{
    color:var(--lightest);
    text-align: center;
    margin: 3rem 6rem 4rem 4rem;
 }
 .offerTextImgCon .offerText2{
    color:var(--lightest);
    text-align: center;
    margin: 0rem;
 }
 .offerTextImgCon p{
    margin: 3rem;
 }
 .circle4{
    background-color: var(--lightest);
    border-radius: 1.75rem;
    width: 100%;
    height: 100%;
    /* width: 20rem;
    height: 20rem; */
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin:2rem 4rem 0rem 0rem */
}
.circle4 img {
    width: 100%;
    height: 100%;
    object-fit:contain;
    align-self: center;
    justify-self: center;
   
}
.allSubSectionOffer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.allSubSection_CardOffer{
    background-image: linear-gradient(var(--lightmedium), var(--lightest));
    padding: 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
}
.allSubSection_CardOffer div{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-bottom: 5rem;
}
.allSubSection_CardOffer img{
    width: 4rem;
    height: 4rem;
}
.allSubSection_CardOffer h1{
    font-size: 3rem;
    font-weight: 800;
}
.allSubSection_CardOffer p{
    padding: 0rem 4rem;
    font-weight: 800;
    color: var(--moredark);
}
.flowContainer, .flowImages{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.flowContainer{
    margin-bottom: 3rem;
}
.flowCircle{
    padding: 3rem;
    background-color: var(--lightmedium);
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    align-items: center;
    justify-content: center;
    display: flex;
}
.flowHeader{
    color: var(--lightest);
}
.flowLine1,.flowLine2{
    width: 3rem;
    height: 0.3rem;
}
.flowArrow{
    width: 2rem;
    height: 2rem;
}
.btnPaymentContainer{
    display: flex;
    justify-content: center;
}
.buttonPayment {
    color: var(--dark);
    background-color: var(--light);
    border: 1px var(--light) solid;
    border-radius: 3rem;
    font-size: 2rem;
    font-weight: bold;
    padding: 0rem 2rem;
    margin: 3rem;
}

.buttonPayment:hover {
    color: var(--light);
    background-color: var(--darker);
    transition: all 0.2s ease-in-out;
}
.topSubSection{
    margin: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.topSubSection_Card{
    /* background-color:var(--lightmedium); */
    background-image: linear-gradient(var(--lightmedium),var(--lightest));
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    padding: 4rem 2rem;
}
.topSubSection_Card h1{
    color:var(--moredark);
    text-align: center;
}
.topSubSection_Card p{
    text-align: center;
    /* margin: 2rem 0 4rem 0; */
}
.topSubSection_Card li{
    color:var(--moredark);
}
.topSubSection_Card button{
   padding: 1rem;
}
#trial,#reading,#session{
    width: 1rem;
}
input[type='radio'] {
    accent-color: var(--lightmedium);
    -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}
.radioDiv label{
    margin-left: 1rem;
}
.finalSubmit{
    padding: 1rem
}
@media screen and (max-width: 650px) {
    .offerHeader {
        margin: 1rem;
        font-size: 2rem;
    }
    .offerTextImgCon{
        display: flex;
        flex-direction: column-reverse;
        margin: 3rem;
     }
     .allSubSectionOffer{
        display: flex;
        flex-direction: column;
     }
     .allSubSection_CardOffer{
        margin: 1rem 3rem;
     }
     .flowContainer{
        display: flex;
        flex-direction: column;
    }
    .flowImages{
        transform: rotate(90deg);
    }
    .flowCircle{
        margin: 3rem;
    }
    .getAnswersOffers{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
    }
    .getAnswersOffers h2{
        margin-bottom: 1rem;
    }
    .getAnswersOffers li{
       margin-left: 2rem;
    }
    .queryOffer{
        margin: 0 2rem;
    }
    .buttonPayment {
        font-size: 1rem;
        padding: 0rem 1rem;
        margin: 2rem;
        text-align: center;
    }
    .topSubSection{
        margin-top:1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .topSubSection_Card{
        margin: 0 0 2rem 0;
        padding: 1rem 0.5rem;
    }
    .topSubSection_Card p{
        text-align: center;
        /* margin: 1rem 0 1rem 0; */
    }
    
    .radioDiv{
        display: flex;
        flex-direction: column;
    }
    .radioDiv div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .finalSubmit{
        padding: 1rem;
    }
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    input[type='radio'] {
        -ms-transform: scale(0.6); /* IE 9 */
      -webkit-transform: scale(0.6); /* Chrome, Safari, Opera */
      transform: scale(0.6);
    }
 }
